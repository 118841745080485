// aboutData.js

const aboutData = [
    {
        description: 'BSc.Engineering CSE, University of Moratuwa, Sri Lanka',
        image: 'https://upload.wikimedia.org/wikipedia/en/6/60/University_of_Moratuwa_logo.png',
        scale: 1.2,
    },
    {
        description: 'GCE(A/L) Examination 2019, District Rank - 1 Island Rank - 7',
        image: 'https://grace-academy.in/wp-content/uploads/2022/04/man-with-laptop-showing-ok-gesture-4927667-4122903.png',
        scale: 1.65,
    },
    {
        description: 'Successfully completed intern as a full-stack developer at Circles Life.',
        image: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Circles_Life_RGB_Color_Logo.svg',
        scale: 1,
    },
    {
        description: 'Founder - Softnx Technologies by Nimsara',
        image: 'https://drive.google.com/thumbnail?id=1qF3Q4nrnD4KdxsgV04x5Prw8MsEBb20L',
        scale: 1.7,
        badges: ["full-stack", "mobile","iot"],
    },
    {
        description: 'Freelancer - Five Star rated IOT based Fullstack developer at Fiverr.',
        image: 'https://freelogopng.com/images/all_img/1656738600fiverr-app-logo.png',
        scale: 1,
        badges: ["$500+ earn", "9+ projects"],
    },
    {
        description: 'Freelancer - Five Star rated Embedded Systems and Fullstack developer at Upwork.',
        image: 'https://cdn.worldvectorlogo.com/logos/upwork-roundedsquare-1.svg',
        scale: 1,
        badges: ["$1.9K+ earn", "3+ projects"],
    },
    {
        description: '3yr+ experience with IOT technologies.',
        image: 'https://static.vecteezy.com/system/resources/previews/026/428/284/original/smart-home-3d-icon-smart-home-system-concept-internet-of-things-iot-smart-home-and-network-connect-concept-png.png',
        scale: 1.7,
        badges: ["microcontroller", "C++", "mqtt", "sensor", "actuator", "nodered", 'BLE', "wifi","protocols"],
    },
    {
        description: '2yr+ experience with Fullstack technologies.',
        image: 'https://drive.google.com/thumbnail?id=1yOw8d4Lxns4hDyz6eqQWS3JfjzmhK_Qp',
        scale: 1.6,
        badges: ["react", "html", "css", "native", "javascript",'jwt', "python", "java","mysql", "springboot", "firebase"],
    },
    {
        description: 'Project Management & Software Engineering Background.',
        image: 'https://drive.google.com/thumbnail?id=1jih-WrolTm3RQTZYcQVw_oPo0wz5N8hE',
        scale: 1.6,
        badges: ["git", "jira", "google-vm", "oop","google cloud", "docker","kubernetes","algorithms","database"],
    },
    {
        description: 'Listning Musics, Participated Iot Seminar Series from ACM, IEEE Membership, Committee Member in Mora Ventures...',
        image: 'https://drive.google.com/thumbnail?id=1sv7GMNLrd8SHqKURB8ErAsTOg5mSGID4',
        scale: 1.4,
        badges: ["Conversational in english"],
    },
    
];

export default aboutData;
